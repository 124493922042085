import React from 'react';
import { RadioGroup, Radio } from 'informed';
import PropTypes from 'prop-types';

class CustomRadioInput extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpBlock: PropTypes.string,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
  };

  static defaultProps = {
    error: null,
    helpBlock: null,
    validate: null,
    disabled: false,
    options: [],
  };

  render() {
    const {
      field, error, helpBlock, validate, disabled, options,
    } = this.props;

    return (
      <div>
        <RadioGroup field={field} validate={validate}>
          {options.map(item => (
            <div className="radio-button-group mt-3" key={item.value}>
              <Radio value={item.value} id={item.value} disabled={disabled} className="radio-button-input" />
              <span className="radio-button-title">{item.label}</span>
            </div>
          ))}
        </RadioGroup>
        {helpBlock ? <small>{helpBlock}</small> : null}
        {error ? <small style={{ color: 'red' }}>{error}</small> : null}
      </div>
    );
  }
}

export default CustomRadioInput;
