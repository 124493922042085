import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ category, children, onClick }) => (
  <div className={`alert alert-${category} alert-dismissible fade show`} role="alert">
    {children}
    <button type="button" className="close" aria-label="Close" onClick={onClick}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
);

Alert.propTypes = {
  category: PropTypes.oneOf(['success', 'danger', 'warning', 'info']),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Alert.defaultProps = {
  category: 'success',
  children: null,
  onClick: null,
};

export default Alert;
