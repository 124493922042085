import React from 'react';
import PropTypes from 'prop-types';
import Table from './Table';

class PlagiusAddresses extends React.Component {
  get addressColumns() {
    return [
      {
        Header: 'Link',
        accessor: 'url',
        Cell: row => <a href={row.value} rel="noreferrer noopener" target="_blank">{row.value}</a>,
      },
      {
        Header: 'Ocorrência',
        accessor: 'ocorrencia',
        width: 100,
      },
      {
        Header: 'Semelhança (%)',
        accessor: 'semelhanca',
        width: 120,
        Cell: row => row.value.toLocaleString('pt'),
      },
    ];
  }

  get pageSize() {
    const { addresses } = this.props;
    return addresses.length;
  }

  render() {
    const { addresses } = this.props;

    if (addresses.length === 0) {
      return <p>Nenhum endereço encontrado.</p>;
    }
    return (
      <Table
        data={addresses}
        columns={this.addressColumns}
        pageSize={this.pageSize}
        showPageSizeOptions={false}
        showPagination={false}
      />
    );
  }
}

PlagiusAddresses.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.object),
};

PlagiusAddresses.defaultProps = {
  addresses: [],
};

export default PlagiusAddresses;
