import React from 'react';
import PropTypes from 'prop-types';

export default class ProgressBar extends React.Component {
  static propTypes = {
    progress: PropTypes.number,
    align: PropTypes.string,
  };

  static defaultProps = {
    progress: 0,
    align: 'left',
  };

  render() {
    const { progress, align } = this.props;
    return (
      <div className="mb-4 row">
        <div className="col-sm-12">
          <div className={`progress-title text-${align}`}>{progress.toLocaleString('pt')}%</div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>
    );
  }
}
